import React, { createContext, useContext, useState } from 'react';

//languase
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HedProfileEN from '../../../custom/language/En.json';
import HedProfileBN from '../../../custom/language/Bn.json';
import HedProfileFR from '../../../custom/language/Fr.json';
import HedProfileAR from '../../../custom/language/Ar.json';
import HedProfileHI from '../../../custom/language/Hi.json';

  let  get_Lang;
  const sel_Language = localStorage.getItem('selectedLanguage');
  if (sel_Language !== null) {
   get_Lang = sel_Language;
  } else {
   get_Lang = "en";
   localStorage.setItem('selectedLanguage', get_Lang); 
  }

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: HedProfileEN },
    bn: { translation: HedProfileBN },
    fr: { translation: HedProfileFR },
    ar: { translation: HedProfileAR },
    hi: { translation: HedProfileHI },
  },
  lng: get_Lang, // Default language
  fallbackLng: get_Lang,
  interpolation: {
    escapeValue: false,
  },
});

const LanguageContext = createContext();

export const GlobalProvider = ({ children }) => {
  let  CurrencySymbol;
  let  selectedCurrency;
  if (localStorage.getItem('CurrencySymbol') !== null && localStorage.getItem('selectedCurrency') !== null ) {
   CurrencySymbol = localStorage.getItem('CurrencySymbol');
   selectedCurrency = localStorage.getItem('selectedCurrency');
  } else {
   CurrencySymbol = "$";
   localStorage.setItem('CurrencySymbol', CurrencySymbol);  
   selectedCurrency = "usd";
   localStorage.setItem('selectedCurrency', selectedCurrency);  
  }
  var  BaseUrl = "https://backoffice.slotediscover.com/";
  return (
    <LanguageContext.Provider value={{ CurrencySymbol, BaseUrl }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const GlobalSet = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a GlobalProvider');
  }
  return context;
};
